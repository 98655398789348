import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import logo from '../img/logo/centerux-logo.png';

function ResponsiveNavbar() {
  const [selectedLink, setSelectedLink] = useState('Home');

  const handleNavClick = (link) => {
    setSelectedLink(link);
  };
  return (
    <Navbar sticky="top" className="navbar nav navbar-expand-lg blur" expand="lg">
      <Container>
        <Navbar.Brand href="/"><img src={logo} style={{ width: '50px' }} alt='Logo' /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <Nav.Link
              href="/"
              className={`nav-item nav-link ${selectedLink === 'Home' ? 'active' : ''}`}
              onClick={() => handleNavClick('Home')}
            >
              Home
            </Nav.Link>
            <Nav.Link
              href="#about"
              className={`nav-item nav-link ${selectedLink === 'About' ? 'active' : ''}`}
              onClick={() => handleNavClick('About')}
            >
              About
            </Nav.Link>
            <Nav.Link
              href="#experience"
              className={`nav-item nav-link ${selectedLink === 'Experience' ? 'active' : ''}`}
              onClick={() => handleNavClick('Experience')}
            >
              Experience
            </Nav.Link>
            <Nav.Link
              href="#contact"
              className={`nav-item nav-link ${selectedLink === 'Contact' ? 'active' : ''}`}
              onClick={() => handleNavClick('Contact')}
            >
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default ResponsiveNavbar;