import React from 'react';
import WOW from 'wowjs';

class SectionSeparator extends React.Component {
    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();
    }
    render() {
        return (
            <>
                <div className="wow slideInLeft mb-5 hidden-xxs hidden-xs hidden-sm" data-wow-delay="0.1s" id="separator">
                    <div className="section-separator">
                    </div>
                </div>

            </>
        );
    }
}

export default SectionSeparator;
