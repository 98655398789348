import React from 'react';
import WOW from 'wowjs';
import HeroImage from '../img/hero-strategy.jpg'
import HeroImageLeft from '../img/hero-strategy-left.png'

class Hero extends React.Component {
    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();
    }
    render() {
        return (
            <>
                <div className='hero-bg overflow-hidden'>
                    <div className="hero hero-bg-img max-width" id="home">
                        <div className="container mt-4">
                            <div className="row h-100">
                                <div className="col-sm-12 col-md-1 hidden-xxs hidden-xs hidden-sm wow slideInLeft">
                                    <img className='hero-strategy-left' src={HeroImageLeft} alt="Ali" />
                                </div>
                                <div className="col-sm-12 col-md-6 mt-5">
                                    <div className="hero-content">
                                        <div className="hero-text mb-3 text-start">
                                            <p className='lead'>Digital Product Strategy</p>
                                            <div className='underline-small'></div>
                                            <h1 className='display-1 fw-bolder hero-title-custom text-bg' style={{ zIndex: '1' }}>Design of the Future</h1>
                                        </div>
                                        <hr className='text-light' />
                                        <div className="text-start mt-5 mb-5">
                                            Our story is one of growth and innovation, beginning with a vision and evolving into a tale of success. We've navigated the ever-changing business landscape, consistently adjusting our strategies to remain at the forefront of industry trends.                                        </div>
                                        <div className="text-center mb-5" >
                                            <a href="#contact" className="btn btn-warning rounded-pill text-center">Get In Touch</a>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4 d-md-block mb-5">
                                    <div className="hero-image pe-0 wow slideInRight" data-wow-delay="0.1s">
                                        <img className='hero-image' src={HeroImage} alt="Ali" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default Hero;
