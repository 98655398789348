import React from 'react';
import WOW from 'wowjs';
import TopNav from '../pages/TopNav';
import Hero from '../sections/Hero';
import About from '../sections/About';
import BackToTopButton from '../generic/BackToTopButton';
import Footer from '../pages/Footer'
import Strategy from '../sections/Strategy';
import ContactForm from '../sections/ContactForm';

class App extends React.Component {
    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();
    }
    render() {
        return (
            <>
                <TopNav />
                <Hero />
                <About />
                <Strategy />

                <div className='separator'></div>
                <div className='custom-bg'>
                    <div className="custom-shape-divider-top-1688373964">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M1200 0L0 0 892.25 114.72 1200 0z" className="shape-fill"></path>
                        </svg>
                    </div>

                </div>

                <ContactForm />
                <BackToTopButton />
                <Footer />

            </>
        );
    }
}

export default App;
