
import { ContactUs } from '../generic/Email';
import React from 'react';

const ContactForm = () => {
    return (
        <section className="max-width bg-simple" id="contact">
            <div className="container max-width p-5">
                <div className='row justify-content-end wow slideInRight'>
                    <div className="col-md-6 col-xs-12 text-center mb-5">
                        <p className="text-uppercase text-muted">Sooner the better</p>
                        <h2 className="display-4 fw-bold">Let's Plan</h2>
                        <div className='title-underline mb-4'></div>
                        <ContactUs />
                    </div>

                </div>
            </div>
        </section>
    );
};

export default ContactForm;

