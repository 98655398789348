import React from 'react';

class App extends React.Component {
    render() {
        return (
            <div className="App overflow-x-hidden">
                <div id="footer" className="max-width p-5 footer wow fadeIn" data-wow-delay="0.2s">
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4 col-xs-12 mb-2 text-white">
                                    <div className="footer-info square-cols">
                                        <div className="footer-social">
                                            <img className='m-2' src={require("../img/logo/centerux-favicon.png")} style={{ height: '180px', borderRadius: '20px' }} alt="Logo" />
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            <div className='mb-3'>
                                                <h4 className='text-start mb-0 text-white'>Address</h4>
                                                <div className='text-start footer-text text-white'>Espoo, Finland</div>
                                                <div className='text-start footer-text text-white d-none'>044 2 844 844</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-8 col-xs-12 mb-2">
                                    <div className="footer-info square-cols" style={{ minHeight: '256px' }}>
                                        <div className='mt-3 fs-5 text-start p-5'>
                                            <div className='mb-3 text-center'>
                                                <h2 className='mb-0 text-white'>Center UX</h2>
                                                <div className='footer-text text-white'>Digital Product Strategy</div>
                                                <h2 className='text-white mt-5'>Innovate, Design, Develop</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container copyright">
                            <p>&copy; Center UX 2023 | Made With React JS</p>
                        </div>
                    </div>
                </div>


            </div>

        );
    }
}

export default App;
