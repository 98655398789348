import React from 'react';
import SectionSeparator from '../generic/SectionSeparator';

const experiences = [
  {
    phase: 'Phase 1',
    objective: 'Establish a solid foundation for product development.',
    description: 'Conduct Market Research, Define Product Vision, Team Building, Stakeholder Engagement:',
  },
  {
    phase: 'Phase 2',
    objective: 'Define the product\'s core features and functionality.',
    description: 'User Personas, Feature Prioritization, Wireframing and Prototyping, Technology Stack:',
  },
  {
    phase: 'Phase 3',
    objective: ' Begin development and testing of the product.',
    description: 'Development Kick-off, User Testing, Iterative Development, Quality Assurance:',
  },
  {
    phase: 'Phase 4',
    objective: 'Prepare for product launch.',
    description: 'Beta Testing, Marketing and Promotion, Documentation, Launch:',
  },

];

const Strategy = () => {
  return (
    <div className="experience mb-5 p-5 max-width" id="experience">
      <div className="container">
        <SectionSeparator />
        <header className="section-header text-center wow zoomIn" data-wow-delay="0.1s">
          <p className="sectionSeparator">Start Planning</p>
          <h2>A Product Roadmap</h2>
        </header>
        <div className="timeline">
          {experiences.map((strategy, index) => (
            <div
              className={`timeline-item ${index % 2 === 0 ? 'left' : 'right'} wow zoomIn${index % 2 === 0 ? 'Left' : 'Right'
                }`}
              data-wow-delay={`${0.1 + index * 0.1}s`}
              key={index}
            >
              <div className="timeline-text text-start">
                <div className="timeline-date">{strategy.phase}</div>
                <h2>{strategy.objective}</h2>
                <p>{strategy.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Strategy;
