import React from 'react';
import WOW from 'wowjs';

class About extends React.Component {
    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();
    }
    render() {
        return (
            <>
                <div className="about wow fadeIn mt-5 mb-5 max-width p-5" data-wow-delay="0.1s" id="about">
                    <div className="container">
                        <div className="row">
                            <div className="mt-5">
                                <div className="about-content">
                                    <div className="section-header text-center wow zoomIn">
                                        <p className='sectionSeparator'>About Us</p>
                                        <h2>10 Years Experience</h2>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <div className="row">
                                            <div className="col-md-4 wow slideInLeft">
                                                <p>
                                                    Over time, we've assisted numerous clients and partners in turning their product visions into tangible successes. Our commitment to excellence and a profound passion for innovation have driven our accomplishments.
                                                </p>
                                            </div>
                                            <div className="col-md-4 wow slideInLeft">
                                                <p>
                                                    We have remained committed to staying ahead of the curve, embracing emerging technologies and methodologies to ensure our clients benefit from the latest, most effective approaches to product strategy.
                                                </p>
                                            </div>
                                            <div className="col-md-4 wow slideInLeft">
                                                <p>
                                                    Looking ahead, our future promises even more groundbreaking product strategies. With our wealth of experience, we are excited to continue shaping the future of products and businesses, one innovative strategy at a time.                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default About;
